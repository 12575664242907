<template>
  <v-text-field
    class="can-disable-selector"
    outlined
    dense
    v-model="time"
    :label="label"
    type="time"
    :rules="rules"
    :disabled="isDisable"
    @input="update"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: {type: String},
    label: {type: String},
    rule: {type: Array},
    isDisable: {type: Boolean},
  },
  methods: {
    update() {
      this.$emit('input', this.time)
    },
  },
  watch: {
    value() {
      this.time = this.value
    }
  },
  data() {
    return {
      menu: false,
      time: this.value,
      rules: this.rule || [v => !!v || 'Time is required']
    }
  }
}
</script>

<style lang="scss">
// Icon style
input[type="time"]::-webkit-calendar-picker-indicator {
  font-size: 24px;
  filter: opacity(0.54);
}
.error--text  > .v-input__control > .v-input__slot > .v-text-field__slot > input[type="time"]::-webkit-calendar-picker-indicator {
  font-size: 24px;
  // Red color
  filter: invert(51%) sepia(80%) saturate(3016%) hue-rotate(327deg) brightness(101%) contrast(106%);
}
</style>
