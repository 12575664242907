<template>
  <v-dialog v-model="show" persistent max-width="450">
    <v-card>
      <v-card-title class="headline" t-data="headline">
        <h4 class="headerTextColor--text">Publish share link</h4>
      </v-card-title>
      <v-card-text t-data="info-label"
        >Before sharing, please generate a link.</v-card-text
      >
      <v-card-subtitle class="sub-body-bold" t-data="info-label"
        >Share Link</v-card-subtitle
      >

      <div class="share-group">
        <input
          type="text"
          v-if="packageObject.share_link"
          :value="packageObject.share_link"
          id="copy-text-id"
          class="copy-text-box primaryTextColor--text"
          readonly
          t-data="form-value"
        />
        <span v-else class="info-share disableTextColor--text" t-data="generate-text">
          Generated link will appear here.
        </span>
        <v-btn
          v-if="packageObject.share_link"
          color="borderColor"
          outlined
          class="sub-body-bold"
          t-data="dialog-btn"
          @click="copyLink"
        >
          <div class="text-btn primaryTextColor--text">
            Copy
          </div>
        </v-btn>

        <v-btn
          v-else
          color="borderColor"
          outlined
          class="sub-body-bold"
          @click="clickGenerateLink"
          t-data="dialog-btn"
          :disabled="disableGenerateBtn"
        >
           <div class="text-btn primaryTextColor--text">
            Generate
          </div>
        </v-btn>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="info600Color"
          text
          @click="close"
          class="button-bold btn-close"
          t-data="dialog-btn"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'ShareLinkPopup',
  props: {
    show: {type: Boolean},
    packageId: {type: Number},
    close: {type: Function}
  },
  data() {
    return {
      disableGenerateBtn: false
    }
  },
  methods: {
    copyLink() {
      var copyText = document.getElementById('copy-text-id')
      copyText.select()
      copyText.setSelectionRange(
        0,
        this.packageObject.share_link.length
      ) /* For mobile devices */
      document.execCommand('copy')
      document.getSelection().removeAllRanges()
    },
    async clickGenerateLink() {
      this.disableGenerateBtn = true
      await this.generateShareLink(this.packageId)
      this.disableGenerateBtn = false
    },
    ...mapActions('packages', ['generateShareLink'])
  },
  computed: {
    ...mapState('packages', ['packageObject'])
  }
}
</script>

<style scoped>
.share-group {
  margin: 0px 20px;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
}
.v-dialog > .v-card > .v-card__subtitle {
  padding-bottom: 10px;
}
.share-group input {
  outline: none;
  border: none;
  cursor: none;
  width: 100%;
}
.copy-text-box {
  cursor: pointer !important;
  font-family: 'SukhumvitTadmai';
}
.info-share {
  font-family: 'SukhumvitTadmai';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}
.btn-close {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
</style>
