import {Quill} from 'vue-quill-editor'
const validator = require('validator')
const Link = Quill.import('formats/link')

class CustomLink extends Link {
  static create(value) {
    const node = Link.create(value)
    value = Link.sanitize(value)
    if (validator.isNumeric(value)) {
      node.setAttribute('href', 'tel:' + value)
      node.className = 'link--tel'
    }
    if (validator.isEmail(value)) {
      node.setAttribute('href', 'mailto:' + value)
      node.className = 'link--mail'
    }
    if (value.startsWith('https://') || value.startsWith('http://')) {
      node.className = 'link--external'
    } else {
      node.removeAttribute('target')
    }
    return node
  }
  format(name, value) {
    super.format(name, value)
    this['domNode'].removeAttribute('target')
  }
}

export default CustomLink
