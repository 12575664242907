<template>
  <v-row class="align-center mt-2" style="margin-left: 1px; margin-right: 0;">
    <div class="edit-by-content sub-body-regular" v-html="createdContent"></div>
    <v-spacer></v-spacer>
  </v-row>
</template>

<script>
import {convertDate} from '@/helper/helper'

export default {
  name: 'CreatedDetail',
  props: {
    dataObject: {type: Object},
    createDate: {
      type: String,
      required: false,
      default: ''
    },
    showCreate: {
      type: Boolean,
      required: false,
      default: true
    },
    isPortalUser: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    createdContent() {
      const contents = []
      let createText = ''
      let updateText = ''

      if (
        this.showCreate &&
        'create_by_user' in this.dataObject &&
        this.dataObject.create_by_user !== null &&
        'name' in this.dataObject.create_by_user
      ) {
        createText += `Created by ${this.dataObject.create_by_user.name}`

        if ('is_active' in this.dataObject.create_by_user && !this.dataObject.create_by_user.is_active) {
          createText += ' (Inactive)'
        }

        if (this.createDate) {
          createText += `, ${convertDate(this.createDate)}`
        } else if (this.dataObject.create_date) {
          createText += `, ${convertDate(this.dataObject.create_date)}`
        }
        contents.push(createText)
      }

      if (
        'update_by_user' in this.dataObject &&
        this.dataObject.update_by_user !== null &&
        'name' in this.dataObject.update_by_user
      ) {
        updateText += `Latest updated by `
        if (this.isPortalUser) {
          updateText += 'Portal user :'
        }
        updateText += `${this.dataObject.update_by_user.name}`
        if ('is_active' in this.dataObject.update_by_user && !this.dataObject.update_by_user.is_active) {
          updateText += ' (Inactive)'
        }
        if (this.dataObject.update_date) {
          updateText += `, ${convertDate(this.dataObject.update_date)}`
        }
        contents.push(updateText)
      }

      return contents.filter(v => v.length > 0).join('<br> ')
    }
  }
}
</script>
