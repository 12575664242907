<template>
  <div>
    <v-btn
      v-if="showRemove"
      class="ma-2 white-text-button button-bold"
      t-data="remove-btn"
      color="danger500Color"
      @click="onRemove"
    >
      <v-icon left light>
        mdi-trash-can-outline
      </v-icon>
      Remove
    </v-btn>
    <v-btn
      v-if="showReset"
      class="ma-2 button-bold"
      t-data="reset-btn"
      color="whiteColor"
      :disabled="disabledReset"
      @click="onReset"
    >
        Reset
    </v-btn>
    <v-btn
      class="ma-2 button-bold"
      t-data="cancel-btn"
      color="whiteColor"
      @click="onCancel"
    >
      Cancel
    </v-btn>
    <v-btn
      v-if="showPreview"
      class="ma-2 button-bold"
      t-data="preview-btn"
      color="whiteColor"
      :disabled="disabledPreview"
      @click="onPreview"
    >
      <v-icon left light>
        mdi-magnify
      </v-icon>
      Preview
    </v-btn>
    <v-btn
      v-if="showSave"
      class="ma-2 white-text-button button-bold"
      t-data="save-btn"
      color="primary600Color"
      @click="onSave"
    >
      <v-icon left light>
        mdi-content-save-outline
      </v-icon>
      Save
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'EditButtons',
  props: {
    showRemove: {type: Boolean},
    showReset: {
      type: Boolean,
      required: false,
      default: false
    },
    showPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    showSave: {type: Boolean},
    disabledReset: {
      type: Boolean,
      required: false,
      default: false
    },
    disabledPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    onRemove: {type: Function},
    onReset: {
      type: Function,
      required: false,
      default: () => {}
    },
    onCancel: {type: Function},
    onPreview: {
      type: Function,
      required: false,
      default: () => {}
    },
    onSave: {type: Function},
  },
}
</script>
