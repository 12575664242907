<template>
  <v-container>
    <v-row class="mb-4">
      <v-col :cols="colsImage" :order="orderImage" t-data="info-input">
        <p class="edit-field-label sub-body-bold" t-data="info-label">
          {{ imageFieldName }}
          <span class="danger500Color--text" v-if="requiredImage && showRequiredIcon">*</span>
        </p>
        <image-dropzone
          :thumbnail="urlVal"
          :is-error="isImageError"
          :error-msg="imageErrorMessage"
          :disabled="disabledEdit"
          @update="updateImage"
          @remove="removeImage"
        ></image-dropzone>
      </v-col>
      <slot name="thumbnail-alt"></slot>
      <v-col :cols="colsTitle" :order="orderTitle" t-data="info-input">
        <p class="edit-field-label sub-body-bold" t-data="info-label">
          {{ titleFieldName }}
          <span class="danger500Color--text" v-if="requiredTitle && showRequiredIcon">*</span>
        </p>
        <v-text-field
          class="can-disable-selector"
          :placeholder="titlePlaceholder"
          outlined
          dense
          required
          :disabled="disabledEdit"
          :rules="requiredTitle ? [titleRules.required] : []"
          v-model="titleVal"
        ></v-text-field>
      </v-col>
      <v-col
        v-if="showDescription && isTextareaDescription"
        :cols="colsDescription"
        :order="orderDescription"
        class="mt-4"
        t-data="info-input"
      >
        <p class="edit-field-label sub-body-bold" t-data="info-label">
          Description
        </p>
        <v-textarea
          class="can-disable-selector"
          placeholder="Input Description"
          outlined
          dense
          required
          :disabled="disabledEdit"
          :value="description"
          rows="2"
          row-height="20"
          @input="inputDescription"
          @blur="blurDescription"
        ></v-textarea>
      </v-col>
      <v-col
        v-if="showDescription && !isTextareaDescription"
        class="mt-4"
        :cols="colsDescription"
        :order="orderDescription"
        t-data="info-input"
      >
        <quill-editor
          ref="quillContent"
          :value="description"
          :options="editorOption"
          @input="inputDescription"
          @blur="blurDescription"
        ></quill-editor>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import {quillEditor, Quill} from 'vue-quill-editor'
import ImageUploader from 'quill-image-uploader'

import client from '@/apis/http-client'
import ImageDropzone from '@/components/ImageDropzone'
import MyLink from '@/constants/quill-link'
import {convertFileToBase64} from '@/helper/helper'

Quill.register('modules/imageUploader', ImageUploader)
Quill.register(MyLink)

export default {
  name: 'ContentByLang',
  components: {
    ImageDropzone,
    quillEditor
  },
  props: {
    disabledEdit: {type: Boolean},
    isEditMode: {type: Boolean},
    title: {type: String},
    url: {type: String},
    description: {type: String},
    lang: {type: String},
    setImage: {type: Function},
    setImageError: {type: Function},
    isImageError: {type: Boolean},
    imageErrorMessage: {type: String},
    showDescription: {type: Boolean, required: false, default: false},
    showRequiredIcon: {type: Boolean, required: false, default: true},
    isTextareaDescription: {type: Boolean, required: false, default: false},
    colsImage: {type: String, required: false, default: '6'},
    colsTitle: {type: String, required: false, default: '6'},
    colsDescription: {type: String, required: false, default: '12'},
    orderImage: {type: String, required: false, default: '1'},
    orderTitle: {type: String, required: false, default: '2'},
    orderDescription: {type: String, required: false, default: '3'},
    requiredTitle: {type: Boolean, required: false, default: false},
    requiredImage: {type: Boolean, required: false, default: false},
    imageFieldName: {type: String, required: false, default: 'Image'},
    titleFieldName: {type: String, required: false, default: 'Title'},
    titlePlaceholder: {type: String, required: false, default: 'Content Title'}
  },
  mounted() {
    if (this.showDescription && !this.isTextareaDescription) {
      if (this.disabledEdit) {
        this.disableQuill()
      } else {
        this.enableQuill()
      }
    }
  },
  watch: {
    disabledEdit() {
      if (this.showDescription && !this.isTextareaDescription) {
        if (this.disabledEdit) {
          this.disableQuill()
        } else {
          this.enableQuill()
        }
      }
    }
  },
  data() {
    return {
      errorsOrg: [],
      titleRules: {
        required: value => {
          if (value) {
            return true
          }
          return 'Title is required'
        }
      },
      editorOption: {
        modules: {
          imageUploader: {
            upload: file => {
              return new Promise(async resolve => {
                let base64 = await convertFileToBase64(file)
                let payload = {base64: base64, name: file.name}
                let url = await this.upload(payload)
                resolve(url)
              })
            }
          },
          toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline', 'strike'],
            [{list: 'ordered'}, {list: 'bullet'}],
            ['link', 'image']
          ]
        }
      }
    }
  },
  methods: {
    disableQuill() {
      this.$refs.quillContent.quill.enable(false)
      this.$refs.quillContent.quill.root.dataset.placeholder = ''
    },
    enableQuill() {
      this.$refs.quillContent.quill.enable(true)
      this.$refs.quillContent.quill.root.dataset.placeholder =
        'Insert text here ...'
    },
    inputDescription(val) {
      this.$emit('update:description', val)
    },
    updateImage(file) {
      if (file && file.name) {
        if (!String(file.type).includes('image')) {
          this.setImageError({
            lang: this.lang,
            isError: true,
            message: 'Only support image file!'
          })
          return
        } else if (!file || file.size > 1040000) {
          this.setImageError({
            lang: this.lang,
            isError: true,
            message: 'Image size should be less than 1 MB!'
          })
          return
        } else {
          this.setImage({lang: this.lang, file})
          this.setImageError({lang: this.lang, isError: false, message: ''})
          this.$emit('changeUrl', file)
          return
        }
      } else {
        this.$emit('removeImage')
      }
    },
    removeImage() {
      this.$emit('removeImage')
      if (this.requiredImage) {
        this.setImageError({
          lang: this.lang,
          isError: true,
          message: 'Image is required'
        })
      }
    },
    async upload(payload) {
      try {
        let data = await client.secureHttpClient.post(
          `/por/api/uploadImage`,
          payload
        )
        return data.data.url
      } catch (e) {
        Vue.$log.error(e)
        return ''
      }
    },
    blurDescription() {
      this.$emit('changeDescription', this.description)
    }
  },
  computed: {
    titleVal: {
      get() {
        return this.title
      },
      set(value) {
        this.$emit('changeTitle', value)
      }
    },
    urlVal: {
      get() {
        return this.url
      },
      set(value) {
        this.$emit('changeUrl', value)
      }
    }
  }
}
</script>

<style scoped></style>
