<template>
  <v-dialog v-model="show" :key="componentKey" max-width="600">
    <v-card class="px-6 py-3">
      <v-card-title class="pa-0">
        <h4 style="padding: 6px 0" t-data="headline">Quick Preview</h4>
        <v-spacer></v-spacer>
        <span t-data="image-remove-button" @click="show = false">
          <v-icon class="close-preview-button" light>
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <v-tabs
        v-if="showTab"
        v-model="activeTab"
        class="body-bold status-tab"
        color="primary500Color"
        slider-color="primary500Color"
        align-with-title
        t-data="preview-tab"
      >
        <v-tab href="#en" aria-selected="false">English</v-tab>
        <v-tab href="#th" aria-selected="true">Thai</v-tab>
      </v-tabs>
      <v-tabs-items v-if="showTab" v-model="activeTab">
        <v-tab-item
          t-data="preview-tab-item"
          key="en"
          value="en"
          :class="{'pt-6': true, 'pb-4': showCloseBtn, 'pb-8': !showCloseBtn}"
        >
          <slot name="content-en"></slot>
        </v-tab-item>
        <v-tab-item
          t-data="preview-tab-item"
          key="th"
          value="th"
          :class="{'pt-6': true, 'pb-4': showCloseBtn, 'pb-8': !showCloseBtn}"
        >
          <slot name="content-th"></slot>
        </v-tab-item>
      </v-tabs-items>
      <div v-if="!showTab">
        <slot name="content-th"></slot>
      </div>
      <v-divider v-if="showCloseBtn" class="mb-2"></v-divider>
      <div class="d-flex justify-end">
        <v-btn
          v-if="showCloseBtn"
          large
          color="info600Color"
          @click="show = false"
          class="white-text-button button-bold"
          t-data="close-btn"
          text
        >
          Close
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'QuickPreview',
  props: {
    isPreview: {type: Boolean},
    showCloseBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    showTab: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => ({
    activeTab: 'en',
    componentKey: 0
  }),
  computed: {
    show: {
      get: function() {

        return this.isPreview
      },
      set: function(value) {
        this.activeTab = 'en'
        this.$emit('input', value)
      }
    }
  },
  watch: {
    isPreview() {
      if (this.isPreview) this.componentKey++
    }
  }
}
</script>

<style scoped></style>
