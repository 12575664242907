<template>
  <v-card class="border-card mt-6 mb-4">
    <v-card-text>
      <v-row align="start" justify="start" class="mt-0 px-2">
        <v-col cols="6">
          <h6 class="mb-6 card-title" t-data="thai-info-header">
            Thai Content
          </h6>
        </v-col>
        <v-col cols="2">
          <h6 class="mb-6 card-title" t-data="eng-info-header">
            English Content
          </h6>
        </v-col>
        <v-col t-data="checkbox-duplicate">
          <v-checkbox
            v-model="duplicateContent.package"
            :disabled="disabledEdit"
            hide-details
            label="Duplicate Thai content"
            color="secondary"
            class="check-box-text body-regular mt-0 pt-0"
            @click="duplicateThaiContent"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row align="start" justify="start" class="mt-0">
        <v-col cols="6" t-data="thai-info-content">
          <package-content-by-lang
            class="px-0 ml-3"
            lang="th"
            :alias.sync="packageObject.alias"
            :description.sync="packageObject.contents[0].description"
            :content="packageObject.contents[0]"
            :is-web="isWeb"
            :disabledEdit="disabledEdit"
            :isImageError="isImageThError"
            :imageErrorMessage="imageThErrorMessage"
            :isCoverImageError="isCoverImageThError"
            :coverImageErrorMessage="coverImageThErrorMessage"
            @changeDescription="changeDescriptionTh"
            @changeContent="changeContentTh"
            @changeThumbnail="changeThumbnailTh"
            @removeImage="removeThumbnailTh"
            @changeCoverImage="changeCoverImageTh"
            @removeCoverImage="removeCoverImageTh"
          ></package-content-by-lang>
        </v-col>
        <v-col cols="6" t-data="eng-info-content">
          <package-content-by-lang
            class="px-0"
            lang="en"
            :alias="packageObject.alias"
            :description.sync="packageObject.contents[1].description"
            :content="packageObject.contents[1]"
            :is-web="isWeb"
            :disabledEdit="disabledEdit"
            :isImageError="isImageEnError"
            :imageErrorMessage="imageEnErrorMessage"
            :isCoverImageError="isCoverImageEnError"
            :coverImageErrorMessage="coverImageEnErrorMessage"
            @changeTitle="changeTitleEn"
            @removeImage="removeImageEn"
            @removeCoverImage="removeCoverImageEn"
          ></package-content-by-lang>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import {mapActions, mapState} from 'vuex'
import {isUrl} from '@/helper/helper'

import PackageContentByLang from '@/components/package/PackageContentByLang'
import {displayTypeEnum} from '@/constants/package'

export default {
  name: 'PackageContent',
  components: {
    PackageContentByLang
  },
  props: {
    disabledEdit: {type: Boolean, required: true}
  },
  computed: {
    isWeb() {
      return this.packageObject.display_type === displayTypeEnum.ALL ||
        this.packageObject.display_type === displayTypeEnum.WEB
    },
    ...mapState('packages', [
      'coverImageEnErrorMessage',
      'coverImageThErrorMessage',
      'duplicateContent',
      'imageEnErrorMessage',
      'imageThErrorMessage',
      'isCoverImageEnError',
      'isCoverImageThError',
      'isImageEnError',
      'isImageThError',
      'packageObject',
    ])
  },
  methods: {
    imageRules(lang, val) {
      if (this.isEditMode) {
        this.setImageError({lang, isError: false, message: ''})
        if (isUrl(val)) {
          return
        }
        if (val != '') {
          return
        }
        this.setImageError({
          lang,
          isError: true,
          message: 'Image is required'
        })
        return
      } else {
        if (!val || val === '') {
          this.setImageError({
            lang,
            isError: true,
            message: 'Image is required'
          })
          return
        }
        this.setImageError({lang, isError: false, message: ''})
        return
      }
    },
    coverImageRules(lang, val) {
      if (this.isEditMode) {
        this.setCoverImageError({lang, isError: false, message: ''})
        if (isUrl(val)) {
          return
        }
        if (val != '') {
          return
        }
        this.setCoverImageError({
          lang,
          isError: true,
          message: 'Image is required'
        })
        return
      } else {
        if (!val || val === '') {
          this.setCoverImageError({
            lang,
            isError: true,
            message: 'Image is required'
          })
          return
        }
        this.setCoverImageError({lang, isError: false, message: ''})
        return
      }
    },
    changeDescriptionTh(val) {
      this.packageObject.contents[0].description = val
      if (this.duplicateContent.package) {
        this.packageObject.contents[1].description = val
      }
    },
    changeContentTh() {
      if (this.duplicateContent.package) {
        this.packageObject.contents[1].title = this.packageObject.contents[0].title
        if (this.isWeb) {
          this.packageObject.contents[1].thumbnail_alt = this.packageObject.contents[0].thumbnail_alt
          this.packageObject.contents[1].cover_image_alt = this.packageObject.contents[0].cover_image_alt
        }
      }
    },
    changeThumbnailTh(val) {
      if (this.duplicateContent.package) {
        this.setImage({lang: 'en', file: val})
        this.setImageError({lang: 'en', isError: false, message: ''})
      }
    },
    removeThumbnailTh() {
      this.packageObject.contents[0].thumbnail = ''
      if (this.duplicateContent.package) {
        this.packageObject.contents[1].thumbnail = ''
        this.imageRules(
          this.packageObject.contents[1].code,
          this.packageObject.contents[1].thumbnail
        )
      }
    },
    changeCoverImageTh(val) {
      if (this.duplicateContent.package) {
        this.setCoverImage({lang: 'en', file: val})
        this.setCoverImageError({lang: 'en', isError: false, message: ''})
      }
    },
    removeCoverImageTh() {
      this.packageObject.contents[0].cover_image = ''
      if (this.duplicateContent.package) {
        this.packageObject.contents[1].cover_image = ''
        this.coverImageRules(
          this.packageObject.contents[1].code,
          this.packageObject.contents[1].cover_image
        )
      }
    },
    changeTitleEn(val) {
      this.packageObject.contents[1].title = val
    },
    removeImageEn() {
      this.packageObject.contents[1].thumbnail = ''
    },
    removeCoverImageEn() {
      this.packageObject.contents[1].cover_image = ''
    },
    duplicateThaiContent() {
      if (this.duplicateContent.package) {
        this.packageObject.contents[1].title = this.packageObject.contents[0].title
        this.packageObject.contents[1].thumbnail = this.packageObject.contents[0].thumbnail
        this.packageObject.contents[1].cover_image = this.packageObject.contents[0].cover_image
        this.packageObject.contents[1].description = this.packageObject.contents[0].description
        if (this.isWeb) {
          this.packageObject.contents[1].thumbnail_alt = this.packageObject.contents[0].thumbnail_alt
          this.packageObject.contents[1].cover_image_alt = this.packageObject.contents[0].cover_image_alt
        }
      } else {
        this.packageObject.contents[1].title = null
        this.packageObject.contents[1].thumbnail = ''
        this.packageObject.contents[1].cover_image = ''
        this.packageObject.contents[1].description = ''
        if (this.isWeb) {
          this.packageObject.contents[1].thumbnail_alt = ''
          this.packageObject.contents[1].cover_image_alt = ''
        }
      }
      this.imageRules(
        this.packageObject.contents[1].code,
        this.packageObject.contents[1].thumbnail
      )
      this.coverImageRules(
        this.packageObject.contents[1].code,
        this.packageObject.contents[1].cover_image
      )
    },
    duplicateThaiContentChange() {
      if (this.duplicateContent.package) {
        this.packageObject.contents[1].title = this.packageObject.contents[0].title
        this.packageObject.contents[1].thumbnail = this.packageObject.contents[0].thumbnail
        this.packageObject.contents[1].cover_image = this.packageObject.contents[0].cover_image
        this.packageObject.contents[1].description = this.packageObject.contents[0].description
        if (this.isWeb) {
          this.packageObject.contents[1].thumbnail_alt = this.packageObject.contents[0].thumbnail_alt
          this.packageObject.contents[1].cover_image_alt = this.packageObject.contents[0].cover_image_alt
        }
      }
    },
    ...mapActions('packages', [
      'setImage',
      'setImageError',
      'setCoverImage',
      'setCoverImageError'
    ])
  }
}
</script>
