<template>
  <div>
    <v-row>
      <v-col cols="12" t-data="profile-image" class="py-0">
        <v-img :src="thumbnail" max-height="501"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-4">
        <h4 class="mt-0 card-title" t-data="info-title">
          {{ title }}
        </h4>
      </v-col>
    </v-row>
    <v-row v-if="!isGroupPackage">
      <v-col cols="12" class="align-end d-flex justify-start py-0">
        <h6 class="primary500Color--text" t-data="discount-price">
          {{
            calculateDiscountPrice(
              dataObject.discount,
              dataObject.discount_type,
              dataObject.full_price
            ) + ' Baht'
          }}
        </h6>
        <div
          class="sales-price-preview-discount-price body-regular ml-2"
          t-data="full-price"
          v-if="dataObject.discount && dataObject.discount > 0"
        >
          {{ commaNumber(dataObject.full_price) + ' Baht' }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="body-regular secondaryTextColor--text pt-4 pb-0"
        t-data="publish-date"
      >
        <v-icon left light size="18">
          mdi-calendar-month
        </v-icon>
        {{ dateFmt(dataObject.period_start_date) }} -
        {{ dateFmt(dataObject.period_end_date) }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="body-regular secondaryTextColor--text pt-2 pb-0"
        t-data="org-name"
      >
        <v-icon left light size="18">
          mdi-office-building
        </v-icon>
        {{ orgName }}
      </v-col>
    </v-row>
    <div class="mt-6" v-if="isGroupPackage">
      <h6 class="mb-2" t-data="sub-package-header">Sub package</h6>
      <div
        v-if="hasSubPackage"
        class="d-flex flex-row overflow-x-auto card-overflow"
        t-data="sub-package-content"
      >
        <v-card
          :key="`${lang}-${subPackage.id}`"
          v-for="subPackage in subPackageList"
          class="mr-4 mb-4 sub-package-card"
          t-data="sub-package-card"
        >
          <v-img
            :src="getContentByLang(subPackage.contents, lang, 'thumbnail')"
            class="align-end"
            height="87px"
            cover
          >
          </v-img>
          <v-card-title
            class="text-white card-title sub-package-card-title"
            t-data="sub-package-card-title"
          >
            <div class="sub-body-regular d-inline-block sub-package-title">
              <p
                class="sub-package-title mb-0 typographyPrimaryTextColor--text"
              >
                {{ getContentByLang(subPackage.contents, lang, 'title') }}
              </p>
            </div>
          </v-card-title>
          <v-card-text class="pb-2 pt-0 px-2" t-data="sub-package-card-text">
            <div
              class="primary500Color--text small-bold text-truncate price"
              t-data="sub-package-discount-price"
            >
              {{
                calculateDiscountPrice(
                  subPackage.discount,
                  subPackage.discount_type,
                  subPackage.full_price
                ) + ' Baht'
              }}
              <span
                class="sales-price-preview-discount-price small ml-1"
                t-data="sub-package-full-price"
                v-if="subPackage.discount && subPackage.discount > 0"
              >
                {{ commaNumber(subPackage.full_price) + ' Baht' }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div
        v-else
        class="d-flex justify-center align-center empty-container body-regular secondaryTextColor--text"
        t-data="empty-sub-package"
      >
        {{ emptySubPackageText }}
      </div>
    </div>
    <v-divider
      :class="{'mb-4': true, 'mt-4': !hasSubPackage}"
    ></v-divider>
    <div
      class="content-detail body-regular primaryTextColor--text mt-2"
      t-data="description"
      v-html="description"
    ></div>
  </div>
</template>

<script>
import {
  convertDateFullMonthDate,
  convertIntegerToCommaNumber
} from '@/helper/helper'
import {packageTypeEnum} from '@/constants/package'

export default {
  name: 'PreviewContent',
  props: {
    dataObject: {type: Object},
    orgName: {type: String},
    title: {type: String},
    thumbnail: {type: String},
    description: {type: String},
    lang: {type: String},
    packageType: {type: String},
    subPackageList: {type: Array}
  },
  computed: {
    isGroupPackage() {
      return this.packageType === packageTypeEnum.GROUP
    },
    hasSubPackage() {
      return this.subPackageList.length > 0
    },
    emptySubPackageText() {
      return this.lang === 'en' ? 'Coming soon' : 'เร็วๆนี้'
    }
  },
  methods: {
    getContentByLang(contents, lang, field) {
      const contentsInLang = contents.filter(c => c.code === lang)
      if (contentsInLang.length > 0) {
        return contentsInLang[0][field]
      }
      return ''
    },
    dateFmt: date => convertDateFullMonthDate(date),
    commaNumber: value => convertIntegerToCommaNumber(value),
    calculateDiscountPrice(discount, discountType, fullPrice) {
      // * (note): if false then its percentage
      const discountNum = discount === '' ? 0 : discount
      if (discountType === 'PERCENTAGE') {
        const discountpercentage = (100 - discountNum) / 100
        const price =
          Math.round((fullPrice * discountpercentage + Number.EPSILON) * 100) /
          100
        const formatedPrice = convertIntegerToCommaNumber(
          Math.round(parseFloat(price))
        )
        return price >= 0 ? (price === 0 ? '0' : formatedPrice) : `0`
      } else {
        const price = fullPrice - discountNum
        const formatedPrice = convertIntegerToCommaNumber(price)
        return price >= 0 ? (price === 0 ? '0' : formatedPrice) : `0`
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$card-width: 130px;
$sub-package-content-height: 169px;

.card-title {
  line-height: 22px;
}
.sub-package-card-title {
  padding: 3px 8px 3px 8px;
}
.sub-package-title {
  min-height: 44px;
  max-height: 44px;
  max-width: $card-width;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.price {
  max-width: $card-width;
}
.card-overflow {
  padding-left: 1px;
}
.sub-package-card {
  min-width: $card-width;
  max-width: $card-width;
  max-height: $sub-package-content-height;
  box-shadow: 0px 3px 14px 2px rgba(164, 164, 172, 0.12),
    0px 8px 10px 1px rgba(164, 164, 172, 0.14),
    0px 5px 5px -3px rgba(164, 164, 172, 0.2);
}
.empty-container {
  height: $sub-package-content-height;
}
</style>
