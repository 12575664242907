<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{on}">
      <v-text-field
        class="can-disable-selector"
        outlined
        v-model="date"
        :label="label"
        :placeholder="placeholder"
        append-icon="event"
        readonly
        dense
        :rules="rules"
        v-on="on"
        :disabled="isDisable"
        :clearable="isClearable"
        @click:clear="clear()"
      ></v-text-field>
    </template>
    <v-date-picker
      reactive
      v-model="date"
      no-title
      scrollable
      :allowed-dates="allowedDates"
      @input="update"
      @change="$refs.menu.save(date)"
      :min="min()"
    >
      <!-- <v-spacer></v-spacer> -->
      <!-- <v-btn text color="primary" @click="menu = false">Cancel</v-btn> -->
      <!-- <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn> -->
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment'

export default {
  props:
    {
    value: {type: String},
    label: {type: String},
    allowedDates: {type: Function},
    rule: {type: Array},
    placeholder: {type: String},
    isDisable: {type: Boolean},
    isClearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    min() {
      return moment().format('YYYY-MM-DD')
    },
    update() {
      this.$emit('input', this.date)
    },
    clear() {
      this.date = null
      this.update()
    }
  },
  watch: {
    value() {
      this.date = this.value
    }
  },
  data() {
    return {
      menu: false,
      date: this.value,
      rules: this.rule || [v => !!v || 'Date is required']
    }
  }
}
</script>
