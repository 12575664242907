import moment from 'moment'

export const timeMoreThanCurrentRule = (val, start_date, msg='The start time must be later than the current time.') => {
  const time = new moment(val, 'HH:mm')
  const today = new Date().toISOString().substr(0, 10)
  if (start_date === today) {
    if (time.hours() < new Date().getHours())
      return msg
    if (
      time.hours() === new Date().getHours() &&
      time.minutes() <= new Date().getMinutes()
    )
      return msg
  }
  return true
}

export const timeMoreThanStartRule = (val, start_time, start_date, end_date, msg='The end time must be later than the start time.') => {
  const time = new moment(val, 'HH:mm')
  const startTime = new moment(start_time, 'HH:mm')
  const selectSameDay = end_date !== '' && start_date === end_date
  const selectSameHour = time.hour() === startTime.hours()
  if (selectSameDay) {
    if (time.hour() < startTime.hours())
      return msg
    if (selectSameHour && time.minutes() <= startTime.minutes())
      return msg
  }
  return true
}

export const timeMoreThanEqualStartRule = (val, start_time, start_date, end_date, msg='The end time must be later than or equal the start time.') => {
  const time = new moment(val, 'HH:mm')
  const startTime = new moment(start_time, 'HH:mm')
  const selectSameDay = end_date !== '' && start_date === end_date
  const selectSameHour = time.hour() === startTime.hours()
  if (selectSameDay) {
    if (time.hour() < startTime.hours())
      return msg
    if (selectSameHour && time.minutes() < startTime.minutes())
      return msg
  }
  return true
}
