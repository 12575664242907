<template>
  <div>
    <div
      :class="
        isPricePreviewError
          ? 'sales-price-preview-box-error'
          : 'sales-price-preview-box'
      "
    >
      <div class="text-left">
        <h5
          class="sales-price-preview-title pb-4"
          t-data="sales-price-preview-title"
        >
          Sale Price Preview
        </h5>
      </div>
      <div
        v-if="isShowPriceFirstCreate"
        class="text-center"
        t-data="sales-price-preview-content"
      >
        <h6 class="sales-price-preview-sub-title pb-4">
          Enter a price or discount to preview.
        </h6>
      </div>
      <div
        class="text-left"
        t-data="sales-price-preview-content"
        style="marginLeft:10px"
        v-if="fullPrice >= 0 && !isShowPriceFirstCreate"
      >
        <v-row class="align-center">
          <h2
            class="sales-price-preview-full-price pb-4"
            t-data="discount-price"
          >
            {{ calculateDiscountPrice() + ' Baht' }}
          </h2>
          <h5
            class="sales-price-preview-discount-price pb-4 ml-3 mb-0"
            t-data="full-price"
            v-if="discount && discount > 0"
          >
            {{ convertNumToComma(fullPrice) + ' Baht' }}
          </h5>
        </v-row>
      </div>
    </div>
    <div
      class="d-flex justify-start align-center body-regular small warning800Color--text flex-nowrap"
      t-data="warning-decimal"
    >
      <v-img
        aspect-ratio="1"
        class="value-message-icon mt-0 mr-1 ml-1"
        max-height="16px"
        max-width="16px"
        src="/images/icons/info-warning-icon.png"
      ></v-img>
      <p class="mb-0">
        {{ warningDecimal }}
      </p>
    </div>
    <div
      class="d-flex justify-start align-center body-regular small warning800Color--text flex-nowrap"
      t-data="warning-price"
    >
      <v-img
        aspect-ratio="1"
        class="value-message-icon mt-0 mr-1 ml-1"
        max-height="16px"
        max-width="16px"
        src="/images/icons/info-warning-icon.png"
      ></v-img>
      <p class="mb-0">
        {{ warningPrice }}
      </p>
    </div>
    <div class="sales-price-preview-box-error-message">
      <v-row
        align="center"
        justify="start"
        class="flex-nowrap"
        t-data="sales-price-preview-error"
        v-if="false"
      >
        <p class="edit-error-message sub-body-regular mb-0">
          {{ isPricePreviewErrorMessage }}
        </p>
      </v-row>
    </div>
  </div>
</template>
<script>
import {convertIntegerToCommaNumber} from '@/helper/helper'

export default {
  name: 'SalePricePreview',
  props: {
    isShowPriceFirstCreate: {type: Boolean, required: true},
    fullPrice: {type: Number},
    discount: {type: Number},
    discountType: {type: String}
  },
  data() {
    return {
      isPricePreviewError: false,
      isPricePreviewErrorMessage:
        'The sale price should be more than 0 and less than the product list price.',
      warningDecimal:
        'Decimal point >= 0.5 is rounded up. Decimal point < 0.5 is rounded down.',
      warningPrice:
        'Sale price should be more than 0 and less than the product list price'
    }
  },
  methods: {
    convertNumToComma(value) {
      return convertIntegerToCommaNumber(value)
    },
    calculateDiscountPrice() {
      // * (note): if false then its percentage
      const discount = this.discount === '' ? 0 : this.discount
      if (this.discountType === 'PERCENTAGE') {
        const discountpercentage = (100 - discount) / 100
        const price =
          Math.round(
            (this.fullPrice * discountpercentage + Number.EPSILON) * 100
          ) / 100
        const formatedPrice = convertIntegerToCommaNumber(
          Math.round(parseFloat(price))
        )
        if (price <= 0) {
          this.$emit('error', true)
          this.isPricePreviewError = true
        } else {
          this.$emit('error', false)
          this.isPricePreviewError = false
        }
        return price >= 0 ? (price === 0 ? '0' : formatedPrice) : `0`
      } else {
        Math.round((this.fullPrice - discount + Number.EPSILON) * 100) / 100
        const price = this.fullPrice - discount
        const formatedPrice = convertIntegerToCommaNumber(price)
        if (price <= 0) {
          this.$emit('error', true)
          this.isPricePreviewError = true
        } else {
          this.$emit('error', false)
          this.isPricePreviewError = false
        }
        return price >= 0 ? (price === 0 ? '0' : formatedPrice) : `0`
      }
    }
  }
}
</script>

<style scoped>
.sales-price-preview-box-error {
  width: 100%;
  max-width: 420px;
  min-width: 420px;
  height: 100%;
  padding: 24px;
  border-width: 1px;
  border-radius: 4px;
  border-style: dashed;
  border-color: var(--error-text-color);
}

.sales-price-preview-box {
  width: 100%;
  max-width: 420px;
  min-width: 420px;
  height: 100%;
  padding: 24px;
  border-width: 1px;
  border-radius: 4px;
  border-style: dashed;
  border-color: var(--border-color);
}

.sales-price-preview-title {
  color: var(--primary-text-color);
}

.sales-price-preview-sub-title {
  color: var(--disable-text-color);
}

.sales-price-preview-full-price {
  color: var(--primary-500-color);
}

.sales-price-preview-discount-price {
  color: var(--disable-text-color);
  text-decoration: line-through;
}
</style>
